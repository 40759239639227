<template>
  <div class="video-container">
    <el-row style="width: 100%;">
      <el-col :span="24">
        <div>
          <div id="video-player"></div></div
      ></el-col>
      <el-col :span="24"
        ><div>
          <div class="list">
            <h3 class="title">
              相关评论
              <p class="other">
                <span><i class="el-icon-view"></i> {{ opposeCount }}</span>
                <span title="赞" @click.once="videosAddLike">
                  <svg
                    width="1.3em"
                    height="1.3em"
                    viewBox="0 0 24 24"
                    class="ZDI ZDI--ThumbFill24 css-15ro776"
                    fill="currentColor"
                  >
                    <path
                      d="M8.5 4.078c0-1.834 1.986-2.979 3.573-2.06a4.826 4.826 0 0 1 2.379 4.71l-.114 1.022h3.581c2.53 0 4.334 2.454 3.58 4.868l-1.823 5.833a3.784 3.784 0 0 1-3.848 2.64c-2.372-.147-6.042-.341-8.828-.341H4.5A1.75 1.75 0 0 1 2.75 19V9.5c0-.967.784-1.75 1.75-1.75h.637a3.418 3.418 0 0 0 3.19-2.191c.115-.296.173-.611.173-.928v-.553Z"
                    ></path>
                  </svg>
                   {{ likeCount }}</span
                >
              </p>
            </h3>
            <ul>
              <li v-for="(item, index) in commentsList" :key="index">
                <p class="top">{{ item.content }}</p>
                <p class="bottom">
                  <span style="padding-right: 5px;"
                    >日期:{{ item.createTime }}</span
                  ><span>作者:{{ item.userName }}</span>
                </p>
              </li>
            </ul>
            <el-pagination
              v-if="total"
              background
              @current-change="changePage"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
            <div class="text">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="content"
              >
              </el-input>
              <a class="btn" @click="submit">提交</a>
            </div>
          </div>
        </div></el-col
      >
    </el-row>
  </div>
</template>

<script>
import {
  comments,
  saveComments,
  videosAddLike,
  videosAddPlay,
} from "@/api/Mange/Mange";

import Player from "xgplayer";
export default {
  props: {
    videoData: Array, //定义传值的类型<br>
  },
  data() {
    return {
      likeCount: 0,
      opposeCount: 0,
      total: "",
      content: "",
      commentsList: [],
      dialogVisible: false,
      myPlayer: "",
      showVideo: {
        value: false,
      },
      queryParams: {
        pageNo: 1,
        size: 10,
        videoId: "",
      },
    };
  },
  mounted() {},
  methods: {
    videosAddPlay() {
      videosAddPlay(this.queryParams.videoId);
    },
    videosAddLike() {
      videosAddLike(this.queryParams.videoId).then((res) => {
        this.likeCount = this.likeCount + 1;
      });
    },
    changePage(pageNo) {
      this.queryParams.pageNo = pageNo;
      this.getComments();
    },
    submit() {
      let params = {
        content: this.content,
        videoId: this.queryParams.videoId,
      };
      saveComments(params).then((res) => {
        if (res.status) {
          this.$message("保存成功");
          this.content = "";
          this.getComments();
        } else {
          this.$message("保存失败");
        }
      });
    },
    showDialog(data) {
      this.likeCount = data.likeCount;
      this.opposeCount = data.opposeCount;
      this.queryParams.videoId = data.videoId;
      this.videosAddPlay();
      this.getComments();
      this.dialogVisible = true;
      this.showVideo.value = true;
      this.myPlayer = this.initVideo(data.videoPath, data.speedType);
      // this.myPlayer.getCssFullscreen();
      this.myPlayer.playbackRate = data.speedType;
      this.myPlayer.play();
      this.myPlayer.on("exitCssFullscreen", (res) => {
        this.showVideo.value = false;
        this.routeValue.value = [];
        this.myPlayer.destroy(true);
      });
    },
    getComments() {
      comments(this.queryParams).then((res) => {
        if (res.status) {
          this.commentsList = res.data.records;

          this.$nextTick(() => {
            this.total = parseInt(res.data.total);
          });
        } else {
          this.$message("评论列表获取错误");
        }
      });
    },

    initVideo(video_url, speed) {
      var player = new Player({
        id: "video-player",
        url: video_url,
        videoInit: true,
        ignores: ["fullscreen"],
        defaultPlaybackRate: speed,
        cssFullscreen: true,
        fluid: true,
      });
      return player;
    },
  },
};
</script>
<style lang="less" scoped>
.video-container {
  // height: 500px;
  text-align: left;
  .list {
    padding: 10px;
    li {
      display: block;
      border-bottom: 1px solid #6464;
      line-height: 30px;
      position: relative;
      width: 100%;
      .top {
        width: 80%;
      }
    }
    .title {
      font-weight: normal;
      line-height: 30px;
      .other {
        float: right;
        span {
          padding-left: 20px;
        }
      }
    }
    .btn {
      width: 160px;
      height: 40px;
      background-color: #d14649;
      color: #3e3131;
      text-align: center;
      line-height: 40px;
      display: block;
      margin: 10px auto;
      text-decoration: none;
    }
    .css-15ro776 {
      display: inline-block;
      vertical-align: sub;
      cursor: pointer;
    }
    .css-15ro776:hover {
      color: #ccc;
    }

  }
}
</style>
