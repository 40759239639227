<template>
  <div class="container">
    <el-carousel :height="screenheight"  loop :autoplay="true" :interval="5000">
      <el-carousel-item v-for="item in banners" :key="item">
        <img :src="item" height="100%" width="100%"/>
      </el-carousel-item>
    </el-carousel>
    <el-main class="main">
      <h1 class="title">{{ row.name }}</h1>
      <el-row>
        <el-col :span="8">
          <el-select
            class="selector"
            v-model="tourType"
            @change="selectType"
            size="medium"
          >
            <el-option
              v-for="item in tourTypeoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <div class="selector">
            <el-select
              v-model="routeValue"
              @change="handleChange"
              filterable
              placeholder="选择路线"
            >
              <el-option
                v-for="item in routeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="8">
          <el-select
            class="selector"
            @change="selectType"
            v-model="speedType"
            size="medium"
          >
            <el-option
              v-for="item in speedOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div v-if="dialogVisible">
        <el-dialog
          :title="routeValue"
          top="20px"
          :close-on-click-modal="false"
          append-to-body
          :visible.sync="dialogVisible"
          width="60%"
          :before-close="handleClose"
        >
          <videoShow ref="videoShow"></videoShow>
        </el-dialog>
      </div>
    </el-main>
  </div>
</template>

<script>
import videoShow from "@/views/Campus/components/dialog/videoShow.vue";
import { videosOptions, videosDetail, getSchoolById, settingsDetail } from "@/api/Mange/Mange";
import { getUser, isLogin } from "@/utils/storage";

export default {
  components: {
    videoShow,
  },
  data() {
    return {
      systemSettins: {},
      schoolId: '',
      arr:[],
      banners: [],
      screenheight: document.documentElement.clientHeight + "px",
      dialogVisible: false,
      row: "",
      myPlayer: "",
      showVideo: {
        value: false,
      },
      msg: "标题",
      tourType: "1",
      routeValue: "",
      speedType: 1,
      tourTypeoptions: [
        {
          value: "1",
          label: "院系导览",
        },
        {
          value: "0",
          label: "风景导览",
        },
      ],
      routeOptions: [],
      speedOptions: [
        {
          value: 0.7,
          label: "步行",
        },
        {
          value: 1,
          label: "骑行",
        },
      ],
    };
  },
  created() {
    window.onresize = () => {
      return () => {
        this.screenheight = document.documentElement.clientHeight + "px";
      };
    };
  },
  async mounted() {
    const that = this;

    this.schoolId = this.$route.params.schoolId;

    const res = await settingsDetail()
    this.systemSettins = res.data;

    // 判断是否公开浏览
    if (!this.systemSettins.openView && !isLogin()) {
      this.$message({
        message: '请登录后继续查看',
        type: 'warning',
        onClose() {
          that.$router.push('/');
        }
      });
    } else {
      // 公开浏览
      getSchoolById(this.schoolId).then(res => {
        this.row = res.data;
        this.banners = this.row.banners.split(",");
        this.videoList();
      })
    }
  },
  methods: {
    videosAddPlay() {
      videosAddPlay(this.queryParams.videoId);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    //视频列表
    videoList() {
      let params = {
        schoolId: this.row.id,
        isCollege: this.tourType,
        isCycling: this.speedType,
        key: "",
      };
      videosOptions(params).then((res) => {
        if (res.status) {
          this.routeValue = "";
          this.routeOptions = [];
          this.routeOptions = res.data;
        }
      });
    },
    selectType() {
      this.videoList();
    },

    handleChange(pathValue) {
      let queryParams = "";
      this.routeOptions.forEach((item) => {
        if (item.label == pathValue) {
          queryParams = item.value;
        }
      });
      this.arr.push(queryParams);
      let l;
      l = JSON.parse(localStorage.getItem('queryParams'));
      if(!(l&&l.length>2)){
        localStorage.setItem("queryParams", JSON.stringify(this.arr));
      }

      if (!getUser()) {
        l = JSON.parse(localStorage.getItem('queryParams'));
        if (this.arr.length > 2 || l.length >= 2) {
          this.$message("登录用户可查看更多视频！");
          return
        }
      }
      videosDetail(queryParams).then((res) => {
        if (res.status) {
          // 找到视频并播放
          let params = {
            likeCount: res.data.likeCount,
            opposeCount: res.data.opposeCount,
            videoPath: res.data.videoPath,
            speedType: this.speedType,
            videoId: res.data.id,
          };
          this.dialogVisible = true;
          this.$nextTick(() => {
            this.$refs["videoShow"].showDialog(params);
          });
        } else {
          this.$message("获取视频失败");
        }
      });
    },
  },
};
</script>
<style scoped>
.main {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
}
.el-carousel {
  width: 100%;
}

.container {
  max-width: 100%;
  overflow: hidden;
  height: 100%;
  /* background-image: url("./img/bg-1.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center; */
  text-align: center;
}

.title {
  color: whitesmoke;
  font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei",
    sans-serif;
  margin-left: 32%;
  margin-right: 32%;
  margin-top: 10%;
  margin-bottom: 80px;
  font-size: 48px;
  backdrop-filter: contrast(50%);
}

.selector {
  margin: 5px;
}


ul {
  list-style-type: none;
  padding: 0;
}
.el-carousel__item img {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.el-carousel__item img{ opacity: 1;}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-carousel__item.is-animating {
  opacity: 0;
}
.el-carousel__item.is-active {
  opacity: 1;
}

.el-carousel__item.is-animating {
  transition: opacity 0.5s linear !important;
}
</style>
